import * as React from "react"
import Template from "components/template";
import * as config from 'utils/configs/jfgdemolition';

// markup
const JfgdemolitionPage = () => {
  return (
    <Template config={config} />
  )
}

export default JfgdemolitionPage
